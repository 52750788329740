(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:ChampionshipPublic
   *
   * @description
   *
   */
  angular
    .module('neo.services')
    .factory('ChampionshipPublic', ChampionshipPublic);

  function ChampionshipPublic($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/public/championships/:id', {id: '@_id'});
  }
}());
